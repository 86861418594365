// export const SERVER_URL = "https://auretodemoapi.impact-soft.com/";
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const APP_MANAGER_SERVER_URL = process.env.REACT_APP_MANAGER_SERVER_URL;
export const APP_URL = process.env.REACT_APP_URL;

// export const SERVER_URL= "http://auretoapi.nativesystemsltd.com/"

// export const AppManagerURL = "http://appmanagerapi.nativesystemsltd.com/"
// //export const SERVER_URL= "https://localhost:44316/";
// //http://svn.impact-soft.com/svn/Ceres/Development/Code
