import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Show_Meeting_Submenu } from "../../constants/types";
import authService from "../../utils/authUtils";

const SubMenu = ({ submenu }) => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch({ type: Show_Meeting_Submenu, payload: submenu });
		return () => {
			// Anything in here is fired on component unmount.
			dispatch({ type: Show_Meeting_Submenu, payload: [] });
		};
	}, []);
	return null;
};

export const MeetingSubMenu = () => {
	const { featureToggles } = useSelector((state) => state.auth);
	const submenu = [
		{
			key: "spv",
			title: "SPV",
			link: "/meetings",
		},

	];
	if (featureToggles && featureToggles.autoMeetingGenerated)
		submenu.push({
			key: "autoGenerated",
			title: "Auto Generated Meetings",
			link: "/meetings/autogenerated",
		});
	return <SubMenu submenu={submenu} />;
};

export const AssetSubmenu = ({ assetId, companyId }) => {
	const assetSubmenu = [
		{
			key: "assetBasic",
			title: "Basics",
			link: `assets/${assetId}/${companyId}/basic`,
		},
		{
			key: "assetBoard",
			title: "Board",
			link: `assets/${assetId}/${companyId}/board`,
		},
		{
			key: "assetManagers",
			title: "Managers",
			link: `assets/${assetId}/${companyId}/managers`,
		},
		{
			key: "assetShareholders",
			title: "Shareholders",
			link: `assets/${assetId}/${companyId}/shareholders`,
		},
		{
			key: "assetNetAssetValue",
			title: "Net Asset Value",
			link: `assets/${assetId}/${companyId}/netassetvalue`,
		},
		{
			key: "assetOperations",
			title: "Operations",
			link: `assets/${assetId}/${companyId}/operations`,
		},
		{
			key: "assetFeeAgreement",
			title: "Fee Agreement",
			link: `assets/${assetId}/${companyId}/feeAgreement`,
		},
	];
	return <SubMenu submenu={assetSubmenu} />;
};

export const FundSubmenu = ({ fundId, companyId }) => {
	const fundSubmenu = [
		{
			key: "fundBasic",
			title: "Basic",
			link: `/funds/${fundId}/${companyId}/basic`,
		},
		{
			key: "fundBoard",
			title: "Board",
			link: `/funds/${fundId}/${companyId}/board`,
		},
		{
			key: "fundManagers",
			title: "Manager",
			link: `/funds/${fundId}/${companyId}/managers`,
		},
		{
			key: "fundShareholders",
			title: "Shareholders",
			link: `/funds/${fundId}/${companyId}/shareholders`,
		},
		{
			key: "fundNetAssetValue",
			title: "Net Asset Value",
			link: `/funds/${fundId}/${companyId}/netassetvalue`,
		},
		{
			key: "fundOperations",
			title: "Operations",
			link: `/funds/${fundId}/${companyId}/operations`,
		},
		{
			key: "fundFeeAgreement",
			title: "Fee Agreement",
			link: `/funds/${fundId}/${companyId}/feeAgreement`,
		},
	];
	return <SubMenu submenu={fundSubmenu} />;
};

// MC
export const ManagementCompaniesSubmenu = ({
	managementCompanyId,
	companyId,
}) => {
	const managementCompanySubmenu = [
		{
			key: "managementCompanyBasic",
			title: "Basics",
			link: `/managementCompanies/${managementCompanyId}/${companyId}/basic`,
		},
		{
			key: "managementCompanyBoard",
			title: "Board",
			link: `/managementCompanies/${managementCompanyId}/${companyId}/board`,
		},
		{
			key: "managementCompanyManagers",
			title: "Managers",
			link: `/managementCompanies/${managementCompanyId}/${companyId}/managers`,
		},
		{
			key: "managementCompanyShareholders",
			title: "Shareholders",
			link: `/managementCompanies/${managementCompanyId}/${companyId}/shareholders`,
		},
		{
			key: "managementCompanyFeeAgreement",
			title: "Fee Agreement",
			link: `/managementCompanies/${managementCompanyId}/${companyId}/feeAgreement`,
		},
		{
			key: "managementCompanyFunds",
			title: "Funds",
			link: `/managementCompanies/${managementCompanyId}/${companyId}/funds`,
		},
		{
			key: "managementCompanyAssets",
			title: "Assets",
			link: `/managementCompanies/${managementCompanyId}/${companyId}/assets`,
		},
	];
	return <SubMenu submenu={managementCompanySubmenu} />;
};

export const MeetingsSubmenu = ({ managementCompanyId }) => {
	const meetingsSubmenu = [
		{
			key: "regularMeetings",
			title: "Regular",
			link: `/meetings/${managementCompanyId}/regular`,
		},
		{
			key: "adhocMeetings",
			title: "Adhoc",
			link: `/meetings/${managementCompanyId}/adhoc`,
		},
		{
			key: "circularResolution",
			title: "Circular Resolution",
			link: `/meetings/${managementCompanyId}/circularResolution`,
		},
		{
			key: "todos",
			title: "To Do's",
			link: `/meetings/${managementCompanyId}/listtodos`,
		},
	];

	return <SubMenu submenu={meetingsSubmenu} />;
};

export const SettingsSubmenu = () => {
	const settingSubmenu = [
    { key: "general", title: "General", link: "settings/general" },
    { key: "agendaTemplate", title: "Agenda", link: "settings/agendatemplate" },
    { key: "circularResolution", title: "CR", link: "settings/crtemplates" },
    { key: "holidays", title: "Holidays", link: "settings/holidays" },
    { key: "legalForms", title: "Legal Forms", link: "settings/legalForms" },
    {
      key: "emailTemplates",
      title: "Email Template",
      link: "settings/emailtemplate",
    },
    { key: "docusign", title: "Docu Sign", link: "settings/docusign" },
    {
      key: "meetingRoom",
      title: "Meeting Room",
      link: "/settings/meetingrooms",
    },
  ];

	return <SubMenu submenu={settingSubmenu} />;
};

export const CompanySubmenu = ({ companyId }) => {
	const companySubmenu = [
		{
			key: "companyBasic",
			title: "Basics",
			link: `/companies/${companyId}/basic`,
		},
		{
			key: "companyBoard",
			title: "Board",
			link: `/companies/${companyId}/board`,
		},
		{
			key: "companyManagers",
			title: "Managers",
			link: `/companies/${companyId}/managers`,
		},
		{
			key: "companyShareholders",
			title: "Shareholders",
			link: `/companies/${companyId}/shareholders`,
		},
	];
	return <SubMenu submenu={companySubmenu} />;
};
export const ContactSubmenu = ({ contactId }) => {
	const contactAvailability = [
		{ key: "contacts", title: "Contact", link: `/contacts/${contactId}` },
		{
			key: "availability",
			title: "Holidays",
			link: `/contacts/${contactId}/availability`,
		},
	];
	return <SubMenu submenu={contactAvailability} />;
};
export const ReportTemplateSubmenu = () => {
	const reportTemplates = [
		{ key: "form", title: "Form", link: "/reports/form" },
		{ key: "table", title: "Table", link: "/reports/table" },
		{ key: "section", title: "Section", link: "/reports/section" },
		{
			key: "reportTemplate",
			title: "Report Template",
			link: "/reports/reportTemplates",
		},
	];
	return <SubMenu submenu={reportTemplates} />;
};
