import moment from "moment";
import parse from "html-react-parser";
export function format(formatType, value) {
  switch (formatType) {
    case "date":
      return moment(value).format("DD-MM-YYYY");
    case "dateTime":
      return moment(value).format("DD-MM-YYYY HH:mm");
    case "time":
      return moment(value).format("LT");
    case "number":
      return typeof value === "number"
        ? localStorage.getItem("lang") != "en"
          ? eurozone.format(value)
          : uszone.format(value)
        : typeof value !== "undefined"
        ? value
        : 0.0;
    default:
      return value;
  }
}

const eurozone = Intl.NumberFormat("de-DE", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
const uszone = Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function parseHTML(html) {
  if (html) return parse(html);
}
