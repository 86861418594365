import { Box, CardContent, Grid, IconButton, TextField } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Form, Formik } from "formik";
import { useState } from "react";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import DialogComponent from "../../../components/Dialog";
import { getTranslation } from "../../../heplers/translationHelper";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Cancel } from "@material-ui/icons";
const AddHoliday = ({
  isSubmitting,
  editDay,
  open,
  onClose,
  onSubmit,
}) => {

  const [isPeriod, setIsPeriod] = useState("date");
  const initialValues = {
    startDate: new Date(),
    endDate:  new Date(),
    comments: "",
  };
  const basicValidationSchema = Yup.object().shape({
    startDate: Yup.date().required("Start Date is required"),
    comments: Yup.string()
    .matches(
      /^[a-zA-Z\sÀ-ÿ\u00C0-\u00FF\u0100-\u017F\u0180-\u024F]+$/,
      "Only English, French, and German characters are allowed"
    )
    .required("Comments are required"),
  });
  const { enqueueSnackbar } = useSnackbar();
  const defaultValue = editDay ? editDay : initialValues;
  return (
    <DialogComponent
      fullWidth
      title={getTranslation(
        "Add Holiday",
        "Ajouter un jour férié",
        "Feiertag hinzufügen"
      )}
      open={open}
      onClose={onClose}
    >
      <Formik
        enableReinitialize
        initialValues={defaultValue}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          if (!values.startDate || isNaN(values.startDate.getTime())) {
            enqueueSnackbar("Please select a valid date.", { variant: "error" });
            return; // Prevent submission if date is not valid
          }
          if (!values.endDate || isNaN(values.endDate.getTime())) {
            enqueueSnackbar("Please select a valid date.", { variant: "error" });
            return; // Prevent submission if date is not valid
          }
          actions.setSubmitting(true);
          values = {
            ...values,
            dateType: isPeriod,
          };
          values.endDate=isPeriod==='date'?null:values.endDate
          onSubmit(values, actions);
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          getFieldProps,
          setFieldValue,
        }) => (
          <Form>
            {!editDay ? (
              <ToggleButtonGroup
                value={isPeriod}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                exclusive
                onChange={(e, value) => {
                  if (value !== null && value !== undefined) {
                    setFieldValue("dateType", value);
                    if (value !== "period") {
                      setFieldValue("endDate", null);
                    }
                    setIsPeriod(value);
                  }
                }}
                aria-label="text alignment"
                name="isPeriod"
                size="small"
              >
                <ToggleButton value={"date"} aria-label="left aligned">
                  Date
                </ToggleButton>
                <ToggleButton value={"period"} aria-label="centered">
                  Period
                </ToggleButton>
              </ToggleButtonGroup>
            ) : editDay?.dateType === "date" ? (
              <ToggleButtonGroup
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ToggleButton selected value={"date"}>
                  Date
                </ToggleButton>
                {setIsPeriod("date")}
              </ToggleButtonGroup>
            ) : (
              <ToggleButtonGroup
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ToggleButton
                  selected
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Period
                </ToggleButton>
                {setIsPeriod("period")}
              </ToggleButtonGroup>
            )}
            <CardContent>
              <Grid container spacing={2}>
                {/* Comments */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    multiline
                    fullWidth
                    label={getTranslation(
                      "Comments",
                      "Commentaires",
                      "Kommentare"
                    )}
                    required
                    size="small"
                    variant="outlined"
                    name=""
                    id="comments"
                    {...getFieldProps("comments")}
                    error={touched.comments && Boolean(errors.comments)}
                    helperText={touched.comments && errors.comments}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                {/* Start Date */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        size="small"
                        id="startDate"
                        label={getTranslation("Start Date", "Date de début", "Start Datum")}
                        value={values.startDate}
                        onChange={(value) => {
                          setFieldValue("startDate", value);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "Start Date",
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                        error={
                          touched.startDate &&
                          Boolean(errors.startDate)
                        }
                      />
                    </MuiPickersUtilsProvider>
                </Grid>
                {/* End Date if period is selected from Toggle button */}
                {isPeriod === "period" ? (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        size="small"
                        id="endDate"
                        label={getTranslation("End Date", "Date de fin", "End Datum")}
                        value={values.endDate}
                        onChange={(value) => {
                          setFieldValue("endDate", value);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "End Date",
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                        error={
                          touched.endDate &&
                          Boolean(errors.endDate)
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                ) : null}
              </Grid>
              <br />
              <Box
                pr={1}
                pb={1}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                {editDay ?
                <IconButton onClick={onClose}>
                  <Cancel/>
                </IconButton>  
                :null}
                <ButtonWithLoading
                  title={
                    editDay
                      ? getTranslation(
                          "Update",
                          "Mettre à jour",
                          "Aktualisieren"
                        )
                      : getTranslation("Add", "Ajouter", "Hinzufügen")
                  }
                  size="small"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  onClick={handleSubmit}
                />
              </Box>
            </CardContent>
          </Form>
        )}
      </Formik>
    </DialogComponent>
  );
};
export default AddHoliday;
